import { Redirect, Route } from "react-router-dom";
import { Component } from "react";
import Store, { AppKey } from "../../Global/Store";
const RouteGuard = ( {component: Component, auth, ...rest} ) => {
    // console.log("auth:" + auth)
    const isAuthenticated = new Store().get(AppKey.USER)? true:false;

    // console.log("component:"+Component)
        return(
            <Route {...rest} render={(props) => (
                isAuthenticated === true
                    ? <Component {...props} />
                    : <Redirect to='/login' />
            )} />
        )
    }
    
    export default RouteGuard;