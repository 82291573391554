import "./App.css";
import * as React from "react";
import { Suspense } from "react";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";
import RouteGuard from "../../Components/RouteGuard";
import Store, { AppKey } from "../../../Global/Store";
import history from "../../Components/BrowserHistory"
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'antd/dist/antd.css';
import { Dimmer, Loader } from "semantic-ui-react";
export interface IAppProps {
  example?: string;
}

// const Home = React.lazy(() => import("../Home/Home"));
const SuccessPg = React.lazy(() => import("../Success/Success"));
// const Home = React.lazy(() => import("../Home/Home"));
const Login = React.lazy(() => import("../Login/Login"));
// const NumberSequenceId = React.lazy(() => import("../NumberSequenceId/NumberSequenceId"));
const Home = React.lazy(() => import("../Home/Home"));
const ViewAll = React.lazy(() => import("../ViewAll/ViewAll"));
const CreateDomain = React.lazy(() => import("../CreateDomain/CreateDomain"));
const Subscription = React.lazy(() => import("../Subscription/Subscription"));
const PageNotFound = React.lazy(() => import("../PageNotFound/PageNotFound"));
const NewContract = React.lazy(() => import("../NewContract/NewContract"));
const CreateAdmin = React.lazy(() => import("../CreateAdmin/CreateAdmin"));
const ForgotPassword = React.lazy(() => import("../ForgotPassword/ForgotPassword"));
const Verification = React.lazy(() => import("../Verification/Verification"));
const ChartOfAccounts = React.lazy(() => import("../../Components/TopSidebar/TopSidebar"));
const Sample = React.lazy(() => import("../Sample/Sample"));
const RoleUI = React.lazy(() => import("../Role/RoleRoute"));
// const ActiveUserUI = React.lazy(() => import("../ActiveUser/ActiveUser"));
const UserProfile = React.lazy(() => import("../UserProfile/UserProfile"));
const NewPassword = React.lazy(() => import("../NewPassword/NewPassword"));
const ServiceMonitor = React.lazy(() => import("../ServiceMonitor/ServiceMonitor"));
const Licensing = React.lazy(() => import("../Licensing/Licensing"));
const LicenseManager = React.lazy(() => import("../LicenseManager/LicenseManager"));
const TenantRequests = React.lazy(() => import("../TenantRequests/TenantRequests"));
const ViewTenantRequest = React.lazy(() => import("../ViewTenantRequest/ViewTenantRequest"));
const ContractList = React.lazy(() => import("../ContractList/ContractList"));
const Contract = React.lazy(() => import("../Contract/Contract"));
const ViewSubscription = React.lazy(() => import("../ViewSubscription/ViewSubscription"));
const Agreement = React.lazy(() => import("../Agreement/Agreement"))


export default class App extends React.Component<IAppProps> {
  public render() {
    const auth = new Store().get(AppKey.USER) ;
    const isAuthenticated=auth?true:false
    return (
      <Suspense fallback={
        <Dimmer active={true} inverted>
          <Loader size='large'>Initializing...</Loader>
        </Dimmer>
      }>
        <Router history={history}>
          <Switch>
            <RouteGuard auth ={isAuthenticated}  exact={true} path="/view-subscription" component={ViewSubscription} />
            <RouteGuard auth ={isAuthenticated}  exact={true} path="/tenant-requests" component={TenantRequests} />
            <RouteGuard auth ={isAuthenticated}  exact={true} path="/view-tenant-request" component={ViewTenantRequest} />
            <RouteGuard auth ={isAuthenticated}  exact={true} path="/user-profile" component={UserProfile} />
            <Route exact={true} path="/new-password" component={NewPassword} />
            <RouteGuard auth ={isAuthenticated}  exact={true} path="/role" component={RoleUI} />
            <RouteGuard auth ={isAuthenticated}  exact={true} path="/chart-of-accounts" component={ChartOfAccounts} />
            <Route exact={true} path="/verification" component={Verification} />
            <RouteGuard auth ={isAuthenticated}  exact={true} path="/create-admin" component={CreateAdmin} />
            <Route exact={true} path="/forgot-password" component={ForgotPassword} />
            <RouteGuard auth ={isAuthenticated} component={SuccessPg}  render={(props:any) => <SuccessPg {...props} />} path="/success" />
            <RouteGuard auth ={isAuthenticated}  exact={true} path="/new-contract" component={NewContract} />
            <Route exact={true} path={['/', '/login']} component={Login} />
            <RouteGuard auth ={isAuthenticated}  path="/subscription" component={Subscription} />
            <RouteGuard path='/home' component={Home} auth ={isAuthenticated} />
            <RouteGuard auth ={isAuthenticated}  path="/view-all" component={ViewAll} />
            <RouteGuard auth ={isAuthenticated}  exact={true} path="/service-monitor" component={ServiceMonitor} />
            <RouteGuard auth ={isAuthenticated}  exact={true} path="/licensing" component={Licensing} />
            <RouteGuard auth ={isAuthenticated}  exact={true} path="/license-manager" component={LicenseManager} />
            <RouteGuard auth ={isAuthenticated}  exact={true} path="/contract-list" component={ContractList} />
            <RouteGuard auth ={isAuthenticated}  exact={true} path="/contract" component={Contract} />
            <RouteGuard auth ={isAuthenticated}  exact={true} path="/eula" component={Agreement} />
            <RouteGuard auth ={isAuthenticated}  path="/domain" component={CreateDomain} />
            <Route path="/sample" component={Sample} />
            <Route component={PageNotFound} />
          </Switch>
        </Router>
      </Suspense>
    );
  }
}
